// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()

import "@hotwired/turbo-rails"

require("@rails/activestorage").start()
require("channels")

require('jquery-ujs');

require('jquery');

require("packs/headroom.min");

import Headroom from './headroom'
window.Headroom = Headroom

import Rails from "@rails/ujs";
window.Rails = Rails;

import ahoy from "ahoy.js";

require('jquery-ui');

require("@popperjs/core");
require("bootstrap");

// $( document ).ready(function() {
$( document ).on('turbo:load', function() {
  $( ".site-header .alert" ).click(function() {
    $(this).slideUp("fast");
  });


  $( ".site-header .collapse-btn" ).click(function() {
    $( ".site-header" ).toggleClass( "menu-open" )
    $(this).attr('aria-expanded', function (i, attr) {
      return attr == 'true' ? 'false' : 'true'
    });
  });
  
  if($('#photography-banner-slideshow').length) {
    let photosCount = $("#photography-banner-slideshow > div").length;
    
    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
    
    let firstPhotoRandom = getRandomInt(photosCount) + 1;
    
    let currentPhoto = firstPhotoRandom;
    let nextPhoto = (firstPhotoRandom < photosCount ? firstPhotoRandom + 1 : 1);
    var slideshowIntervalID;
    // console.log("Number of photos: " + photosCount);
    // console.log("Current photo: " + currentPhoto);
    // console.log("Next photo: " + nextPhoto);
    
    $("#photography-banner-slideshow > div").hide();
    $("#photography-banner-slideshow > div:nth-of-type(" + currentPhoto + ")").show();
    
    function slideshowNextPhoto() {
      if(currentPhoto < photosCount) {
        $("#photography-banner-slideshow > div:nth-of-type(" + nextPhoto + ")").fadeIn(700, function() {
          $("#photography-banner-slideshow > div:nth-of-type(" + currentPhoto + ")").hide();
          currentPhoto++;
          if(currentPhoto == photosCount) {
            nextPhoto = 1;
          }
          else {
            nextPhoto++;
          }
        });
      }
      else {
        $("#photography-banner-slideshow > div:nth-of-type(" + nextPhoto + ")").show();
        $("#photography-banner-slideshow > div:nth-of-type(" + currentPhoto + ")").fadeOut(700);
        currentPhoto = 1;
        nextPhoto = 2;
      }
      // console.log("Current photo: " + currentPhoto);
      // console.log("Next photo: " + nextPhoto);
    }
    
    function slideshowAuto(){
      slideshowIntervalID = setInterval(slideshowNextPhoto, 5000);
    }
    
    
    if(photosCount <= 1) {
      console.log("Only one photo.");
    }
    else {
      slideshowAuto();
      
      // when tabs are suspended, the slideshow goes gnarly
      document.addEventListener('visibilitychange', () => {
        if (document.visibilityState !== 'visible') {
          if(slideshowIntervalID) {
            clearInterval(slideshowIntervalID);
          }
          console.log("Hidden tab, slideshow paused.");
        }
        else {
          slideshowAuto();
          console.log("Resumed slideshow.");
        }
      });
    }
  }
  
  $(".playback-controls").show();

});